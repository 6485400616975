import React from 'react';

import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flatMap from 'lodash/fp/flatMap';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import groupBy from 'lodash/fp/groupBy';
import includes from 'lodash/fp/includes';
import isArray from 'lodash/fp/isArray';
import map from 'lodash/fp/map';
import mapValues from 'lodash/fp/mapValues';
import size from 'lodash/fp/size';
import uniqBy from 'lodash/fp/uniqBy';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SubscriptionIcon from '@mui/icons-material/Autorenew';
import LabelsReprintIcon from '@mui/icons-material/Bookmarks';
import DispatchIcon from '@mui/icons-material/CallSplit';
import ProductionIcon from '@mui/icons-material/Colorize';
import FastConsultationIcon from '@mui/icons-material/FlashOn';
import ExportIcon from '@mui/icons-material/GetApp';
import BufferIcon from '@mui/icons-material/GridOn';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import FeedbackIcon from '@mui/icons-material/InvertColors';
import CustomerIcon from '@mui/icons-material/Person';
import PrintIcon from '@mui/icons-material/Print';
import GiftIcon from '@mui/icons-material/Redeem';
import ScienceSharpIcon from '@mui/icons-material/ScienceSharp';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import styled from '@mui/material/styles/styled';
import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

import navigationPermissionsForGroup from 'utils/teammatesAccess/navigationPermissionsForGroup';
import {
  AUTO_DISPATCH,
  BUFFER_SPACES_LIST,
  COLLATERALS_REPRINT,
  CUSTOMER_LIST,
  DATA_PORTAL,
  EXPORTS,
  FAST_CONSULTATION,
  FEEDBACK_LIST,
  GIFT_LIST,
  HELP,
  LABELS_REPRINT,
  ORDER_LIST,
  PRINTERS_ADMIN,
  PRODUCTION_LIST,
  QUALITY_TEST_PALLETIZE_BOXES,
  QUALITY_TEST_PALLETS,
  SUBSCRIPTION_LIST,
  TEAMMATES_LIST,
} from 'utils/teammatesAccess/teammatesPermissions';

import type {
  EnhancedTeammate,
  Factories,
  FetchedTeammate,
  MenuAccess,
  ProductionCells,
  ProductionFactory,
  TeammatesError,
} from './types';

const StyledPalletsIcon = styled(TableChartSharpIcon)`
  transform: scale(-1, -1);
`;

type TeammatesSlice = RootState['teammates'];

const getTeammatesState: OmsSelector<TeammatesSlice> = state => state.teammates;

export const getError: OmsSelector<TeammatesSlice['error']> = createSelector(
  getTeammatesState,
  get('error')
);
export const getErrorKind: OmsSelector<TeammatesError['kind']> = createSelector(
  getError,
  get('kind')
);
export const getStatus: OmsSelector<TeammatesSlice['status']> = createSelector(
  getTeammatesState,
  get('status')
);
export const getTeammates: OmsSelector<EnhancedTeammate[]> = createSelector(
  getTeammatesState,
  get('teammates')
);
export const getPages: OmsSelector<TeammatesSlice['pages']> = createSelector(
  getTeammatesState,
  get('pages')
);

export const getSignedInTeammate: OmsSelector<FetchedTeammate> = createSelector(
  getTeammatesState,
  get('signedInTeammate')
);
export const getSignedInTeammateFirstName: OmsSelector<['first_name']> = createSelector(
  getSignedInTeammate,
  get('first_name')
);
export const getSignedInTeammateLastName: OmsSelector<FetchedTeammate['last_name']> =
  createSelector(getSignedInTeammate, get('last_name'));
export const getSignedInTeammateUsername: OmsSelector<FetchedTeammate['username']> = createSelector(
  getSignedInTeammate,
  get('username')
);

export const getSignedInTeammateGroups: OmsSelector<FetchedTeammate['groups']> = createSelector(
  getSignedInTeammate,
  get('groups')
);

export const getSignedInTeammatePermissions: OmsSelector<FetchedTeammate['permissions']> =
  createSelector(getSignedInTeammate, get('permissions'));

export const getSubMenuAccess: OmsSelector<MenuAccess[]> = createSelector(
  [(_, props) => props.subMenu, getSignedInTeammateGroups],
  (subMenu, groups) => {
    const subMenuItem = flatMap(({ name }) => {
      const navigationPermissions = get(
        'navigationPermissions',
        find({ slug: name }, navigationPermissionsForGroup)
      );

      return filter(item => includes(item.slug, navigationPermissions), subMenu);
    }, groups);

    return uniqBy('name', subMenuItem);
  }
);

export const getSignedInTeammateProductionCells: OmsSelector<ProductionCells> = createSelector(
  getSignedInTeammate,
  get('production_cells')
);

export const getSignedInTeammateProductionFactoriesByName: OmsSelector<ProductionFactory[]> =
  createSelector(
    getSignedInTeammateProductionCells,
    flow(map('production_factory'), uniqBy('name'))
  );

export const getSignedInTeammateProductionFactories: OmsSelector<Factories> = createSelector(
  getSignedInTeammateProductionCells,
  productionCells => {
    if (size(productionCells) === 0) {
      return '';
    }

    return flow(groupBy('production_factory.pubkey'), mapValues(map('name')))(productionCells);
  }
);

const adminPanelSubMenu = [
  {
    name: 'Printers',
    dataTestId: 'nav-printers',
    to: '/adminPanel/printers',
    slug: PRINTERS_ADMIN,
    group: 'Admin Printers',
  },
];

const createMenuItems = state => [
  // CX
  {
    name: 'Customers',
    dataTestId: 'nav-customers',
    to: '/customers',
    icon: <CustomerIcon />,
    slug: CUSTOMER_LIST,
    group: 'CX',
  },
  {
    name: 'Orders',
    dataTestId: 'nav-orders',
    to: '/orders',
    icon: <OrderIcon />,
    slug: ORDER_LIST,
    group: 'CX',
  },
  {
    name: 'Subscriptions',
    dataTestId: 'nav-subscriptions',
    to: '/subscriptions',
    icon: <SubscriptionIcon />,
    slug: SUBSCRIPTION_LIST,
    group: 'CX',
  },
  {
    name: 'Feedbacks',
    dataTestId: 'nav-feedbacks',
    to: '/feedbacks',
    icon: <FeedbackIcon />,
    slug: FEEDBACK_LIST,
    group: 'CX',
  },
  {
    name: 'Gifts',
    dataTestId: 'nav-gifts',
    to: '/gifts',
    icon: <GiftIcon />,
    slug: GIFT_LIST,
    group: 'CX',
  },
  // Fulfillment
  {
    name: 'Production',
    dataTestId: 'nav-production',
    to: '/production',
    icon: <ProductionIcon />,
    slug: PRODUCTION_LIST,
    group: 'Fulfillment',
  },
  {
    name: 'Dispatch',
    dataTestId: 'nav-dispatch',
    to: '/production/dispatch/auto',
    icon: <DispatchIcon />,
    slug: AUTO_DISPATCH,
    group: 'Fulfillment',
  },
  {
    name: 'Buffer',
    dataTestId: 'nav-buffer',
    to: '/production/buffer-spaces',
    icon: <BufferIcon />,
    slug: BUFFER_SPACES_LIST,
    group: 'Fulfillment',
  },
  // Reprint
  {
    name: 'Collaterals Reprint',
    dataTestId: 'nav-collaterals-reprint',
    to: '/production/collaterals-reprint',
    icon: <PrintIcon />,
    slug: COLLATERALS_REPRINT,
    group: 'Reprint',
  },
  {
    name: 'Labels Reprint',
    dataTestId: 'nav-labels-reprint',
    to: 'production/labels-reprint/',
    icon: <LabelsReprintIcon />,
    slug: LABELS_REPRINT,
    group: 'Reprint',
  },
  // Microhold
  {
    name: 'Quality Tests',
    dataTestId: 'nav-quality-tests',
    to: '/production/quality-tests/ongoing',
    icon: <ScienceSharpIcon />,
    slug: QUALITY_TEST_PALLETS,
    group: 'Microhold',
  },
  {
    name: 'Palletize Boxes',
    dataTestId: 'nav-palletize-boxes',
    to: 'production/palletize-boxes/',
    icon: <WidgetsSharpIcon />,
    slug: QUALITY_TEST_PALLETIZE_BOXES,
    group: 'Microhold',
  },
  {
    name: 'Pallets Management',
    dataTestId: 'nav-pallets-management',
    to: 'production/pallets-management/new',
    icon: <StyledPalletsIcon />,
    slug: QUALITY_TEST_PALLETS,
    group: 'Microhold',
  },
  // Data Analysis
  {
    name: 'Exports',
    dataTestId: 'nav-exports',
    to: '/exports',
    icon: <ExportIcon />,
    slug: EXPORTS,
    group: 'Data Analysis',
  },
  {
    name: 'Data Portal',
    dataTestId: 'nav-data-portal',
    to: '/dataportal',
    icon: <InsertChartIcon />,
    slug: DATA_PORTAL,
    group: 'Data Analysis',
  },
  // Administrate
  {
    name: 'Admin Panel',
    dataTestId: 'nav-admin-panel',
    to: null,
    icon: <AdminPanelSettingsIcon />,
    slug: [PRINTERS_ADMIN],
    group: 'Admin panel',
    subMenu: getSubMenuAccess(state, { subMenu: adminPanelSubMenu }),
  },
  {
    name: 'Users',
    dataTestId: 'nav-teammates',
    to: '/teammates',
    icon: <GroupsIcon />,
    slug: TEAMMATES_LIST,
    group: 'Administration',
  },
  // QA
  {
    name: 'Fast',
    dataTestId: 'nav-fast-consultation',
    to: '/fastconsultation',
    icon: <FastConsultationIcon />,
    slug: FAST_CONSULTATION,
    group: 'Administration',
  },
  // Misc
  {
    name: 'Help',
    dataTestId: 'nav-help',
    to: '/help',
    icon: <HelpIcon />,
    slug: HELP,
    group: 'Misc',
  },
];

export const getMenuAccess: OmsSelector<MenuAccess> = createSelector(
  state => state,
  getSignedInTeammatePermissions,
  (state, signedInPermissions = []) => {
    const menuItems = filter(item => {
      if (isArray(item.slug)) {
        const result =
          flow(
            map(slug => {
              return includes(slug, signedInPermissions);
            }),
            filter(Boolean),
            size
          )(item.slug) > 0;
        return result;
      }
      return includes(item.slug, signedInPermissions);
    }, createMenuItems(state));

    return uniqBy('name', menuItems);
  }
);
