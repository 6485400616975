export const CUSTOMER_LIST = 'core.singular_customer_list'; // /customers
export const CUSTOMER_VIEW = 'core.singular_customer_view'; // /customers/*

export const COUPONS_VIEW = 'core.singular_coupons_view'; // /coupons/*

export const ORDER_LIST = 'core.singular_order_list'; // /orders
export const ORDER_VIEW = 'core.singular_order_view'; // /order/*

export const SUBSCRIPTION_LIST = 'core.singular_subscription_list'; // /subscriptions
export const SUBSCRIPTION_VIEW = 'core.singular_subscription_view'; // /subscriptions/:pubkey/*

export const FEEDBACK_LIST = 'core.singular_feedback_list'; // /feedbacks

export const GIFT_LIST = 'core.singular_gift_list'; // /gifts/
export const GIFT_DETAIL = 'core.singular_gift_detail'; // /gifts/:pubkey

export const PRODUCTION_LIST = 'core.singular_production_list'; // /production

export const OLD_FLOW_PRODUCTION_SCREEN = 'core.singular_old_flow_production_screen'; // /production/*
export const NEW_FLOW_PRODUCTION_SCREEN = 'core.singular_new_flow_production_screen'; // /production/scan-to-produce

export const PACK_SCREEN = 'core.singular_pack_screen'; // /production/packing-station && /production/prepack_lab

export const AUTO_DISPATCH = 'core.singular_auto_dispatch'; // /production/dispatch/auto
export const MANUAL_DISPATCH = 'core.singular_manual_dispatch'; // /production/dispatch/manual
export const BUFFER_SPACES_LIST = 'core.singular_buffer_spaces_list'; // /production/buffer-spaces
export const COLLATERALS_REPRINT = 'core.singular_print_collaterals'; // /production/collaterals-reprint',
// to change once permission created on backend side
export const LABELS_REPRINT = 'core.singular_print_collaterals'; // /production/labels-reprint

export const QUALITY_TEST_PALLETIZE_BOXES = 'core.singular_quality_test_palletize_boxes'; // /production/palletize-boxes/
export const QUALITY_TEST_PALLETS = 'core.singular_quality_test_pallets'; // /production/quality-tests && /production/pallets-management && /production/palletsqrcodes

export const EXPORTS = 'core.singular_exports'; // /exports
export const DATA_PORTAL = 'core.singular_data_portal'; // /dataportal

export const SURVEY_VIEW = 'core.singular_survey_view'; // /surveys/:pubkey

export const TEAMMATES_LIST = 'core.singular_teammates_list'; // /teammates
export const TEAMMATES_DETAIL = 'core.singular_teammates_detail'; // /teammates/:pubkey
export const TEAMMATES_EDIT = 'core.singular_teammates_edit'; // /teammates/:pubkey
export const TEAMMATES_EDIT_PERMISSIONS = 'core.singular_teammates_edit_permissions'; // /teammates/:pubkey

export const FAST_CONSULTATION = 'core.singular_fast_consultation'; // /fastconsultation
export const HELP = 'core.singular_help'; // /help
export const HELP_STATUS_VIEWER = 'core.singular_help_status_viewer'; // /help/statuses
export const HELP_BOX_OBJECT_VIEWER = 'core.singular_help_box_object_viewer'; // /help/box-objects

export const SALON_LIST = 'core.singular_salon_list';
export const SALON_VIEW = 'core.singular_salon_view';

export const DEFECT_ORDER_DETAIL = 'core.singular_defect_order_detail'; // trashing items from the order detail page

export const PRINTERS_ADMIN = 'core.singular_printer_admin'; // adminPanel/printers
